import React, { useEffect, useState } from 'react';

import VisuelComponent from '../../components/ContentType/VisuelComponent/VisuelComponent';
import { Button } from '../Button/Button';

import './DiscoverOfferBox.scss';
import { trackCtaGeneral } from '../../Utils/Tracking';
const DiscoverOfferBox = ({
  visuel,
  alt,
  label = 'Découvrez nos offres-',
  link = '/',
  target = '_self',
  page_name,
  page_type
}) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth < 1024 ? true : false);
  }, []);

  const onClickEvent = (button_content) => {
    if (page_name) {
      trackCtaGeneral('clic_cta', page_name, page_type, "accueil", button_content, "cta")
    }
  }

  return (
    <div className="discover_offer_box_component">
      <VisuelComponent
        visuel={visuel}
        alt={alt || ''}
        aspectRationValue={isMobile ? 374 / 430 : false}
      />
      <div className="overlay">
        <Button primary={true} label={label} link={link} target={target} onClickEvent={() => onClickEvent(label)} />
      </div>
    </div>
  );
};

DiscoverOfferBox.defaultProps = {
  //label: 'Default Label',
};

export default DiscoverOfferBox;
